import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Alert } from './components/Alert';
import { YouTube } from './components/YouTube';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "event-export"
    }}>{`Event Export`}</h1>
    <p>{`Events can be exported with just the click of a button. A spreadsheet containing a list of events will be produced, it also includes helpful event metadata that can be used throughout your organisation. You can provide a keyword or utilise filtering options using the normal staff search functionality to determine which events are included in the export.`}</p>
    <h2 {...{
      "id": "export-events-as-a-spreadsheet"
    }}>{`Export Events as a Spreadsheet`}</h2>
    <ol>
      <li parentName="ol">{`While logged in as a staff member, click the Home menu item in the left sidebar`}</li>
      <li parentName="ol">{`Once you are on the page, optionally provide a keyword in the Search Term field, then click Search`}</li>
      <li parentName="ol">{`Search results will be displayed after a pageload. Click the cloud download icon on the top left corner of the results table to commence a download of all the events displayed`}</li>
    </ol>
    <h2 {...{
      "id": "watch-the-video"
    }}>{`Watch The Video`}</h2>
    <YouTube mdxType="YouTube"><iframe src="//www.youtube.com/embed/QH_E0WWDzLo" frameBorder="0" allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe></YouTube>
    <Alert kind="info" mdxType="Alert">Data will not update on the spreadsheet automatically. You will need to download a new spreadhseet to view up to date data.</Alert>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      